import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/configuration/ROUTES';

@Component({
  selector: 'iesa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public ROUTES = ROUTES;
  public opened: boolean;
  public mobile: boolean;

  constructor(public router: Router, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth < 768;
  }

  public toggleMenu(isOpened: boolean) {
    this.opened = isOpened;
    isOpened ? this.renderer.addClass(document.body, 'disable-scroll') : this.renderer.removeClass(document.body, 'disable-scroll');
  }

}
