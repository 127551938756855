<div class="footer-wrapper">
    <footer class="iesa-width">
        <section class="logo-section">
            <a routerLink="/">
                <img class="ie-logo" src="/assets/logo/ie-footer.svg" alt="IE Logo">
            </a>
        </section>
        <section>
            <iesa-icon [icon]="'pin.svg'"></iesa-icon>
            <div class="section-content">
                <p>Inter-Elektro S. A.</p>
                <p>siedziba Zarządu</p>
                <p>aleja Katowicka 265, 05-830 Urzut</p>
            </div>
        </section>
        <section>
            <iesa-icon [icon]="'location.svg'"></iesa-icon>
            <div class="section-content">
                <a routerLink="/">www.iesa.pl</a>
                <a href="mailto:biuro@inter-elektro.com.pl">e-mail: biuro@inter-elektro.com.pl</a>
                <a style="margin-top: 15px;" href="tel:222566535">tel. 22 256 65 35</a>
                <a href="tel:222566536">tel. 22 256 65 36</a>
            </div>
        </section>
    </footer>
</div>
