<div class="header-wrapper">
    <div class="top-bar">
        <div class="iesa-width top-bar-wrapper">
            <a href="https://bast.ie24.pl" target="_blank" rel="noopener noreferrer">
                <img class="bast-logo" src="/assets/logo/bast.svg" alt="BAST Logo">
            </a>
        </div>
    </div>
    <header class="iesa-width">
        <div class="nav-bar">
            <a routerLink="/">
                <img class="ie-logo" src="/assets/logo/ie.svg" alt="IE Logo">
            </a>
            <div class="mobile-menu">
                <iesa-icon (click)="toggleMenu(!opened)" [icon]="opened ? 'close.svg' : 'hamburger.svg'" [size]="16"></iesa-icon>
            </div>
            <nav *ngIf="!mobile || opened">
                <div class="mobile-overlay" (click)="toggleMenu(false)"></div>
                <ul (click)="toggleMenu(false)">
                    <li><a routerLink="/o-firmie" routerLinkActive="active">O firmie</a></li>
                    <li> <a [routerLink]="ROUTES.locations" routerLinkActive="active">Hurtownie i ich oddziały</a></li>
                    <li> <a [routerLink]="ROUTES.producers" routerLinkActive="active">Producenci</a></li>
                    <li><a [routerLink]="ROUTES.newsList" routerLinkActive="active">Aktualności</a></li>
                    <li><a [routerLink]="ROUTES.contact" routerLinkActive="active">Kontakt</a></li>
                </ul>
            </nav>
        </div>
    </header>
</div>
