import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
  constructor(private title: Title ) {}

  public setTitle(t: string): void {
    this.title.setTitle(t);
  }

}