import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ENDPOINTS } from '../configuration/ENDPOINTS';

export interface Producer {
  alias?: string,
  city?: string,
  country_name?: string,
  flat_number?: string,
  house_number?: string,
  id?: number,
  image_url?: string,
  name?: string,
  post_code?: string,
  show_in_iesa?: boolean,
  street?: string,
  tax_number?: string,
  url?: string
}

@Injectable()
export class ProducersService {
  constructor(private api: ApiService) {}

  public getProducers(phrase?: string): Promise<any> {
    function buildQuery() {
      if (!phrase) { return ''; }
      else {
        return `?q=${phrase}`;
      }
    }

    return this.api.request(ENDPOINTS.producers + buildQuery(), 'GET').then(({data}) => data);
  }

}