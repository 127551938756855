import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public router: Router, private configurationService: ConfigurationService) {
    this.configurationService.getConfiguration().subscribe();
  }

}
