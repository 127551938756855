import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './layout/footer/footer.module';
import { HeaderModule } from './layout/header/header.module';
import { ConfigurationService } from './services/configuration.service';
import { NewsService } from './services/news.service';
import { ProducersService } from './services/producers.service';
import { SuppliersService } from './services/suppliers.service';
import { TitleService } from './services/title.service';
import { MetaService } from './services/meta.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    ApiModule
  ],
  providers: [SuppliersService, ProducersService, NewsService, ConfigurationService, TitleService, MetaService],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
