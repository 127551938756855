import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./routes/main-page/main-page.module').then(m => m.MainPageModule)
  },
  {
    path: 'o-firmie',
    loadChildren: () => import('./routes/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'hurtownie',
    loadChildren: () => import('./routes/locations/locations.module').then(m => m.LocationsModule)
  },
  {
    path: 'producenci',
    loadChildren: () => import('./routes/producers/producers.module').then(m => m.ProducersModule)
  },
  {
    path: 'aktualnosci',
    loadChildren: () => import('./routes/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'aktualnosci/:id',
    loadChildren: () => import('./routes/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./routes/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
