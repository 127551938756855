export const ENDPOINTS = {
    producers: '/v2/producers',
    suppliers: '/v2/suppliers',
    newsList: '/v2/articles/news/public',
    getConfiguration: '/v2/settings/public',
    getDirectory: (id: string) => `/v2/directory/${id}`,
    location: (supplierId: number) => `/v2/supplier/${supplierId}/locations`,
    news: (id: number) => `/v2/article/news/${id}`,
    blob: (src: string) => `${src}`,
    newsAttachments: (id) => `/v2/article/${id}/attachments`
}