import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiService {

  constructor(public http: HttpClient) {}
  
  private API_URL: string = environment.API_URL;

  request<T>(url: string, method: string, params?: any, headers?: HttpHeaders, form?: boolean): Promise<T> {
    let parameters = { reportProgress: true };
    if (params && !form) {
      parameters = { ...parameters, ...params };
    } else { parameters = params; }

    const req = headers ?
                new HttpRequest(method, this.API_URL + url, parameters, { headers: headers })
              : new HttpRequest(method, this.API_URL + url, parameters);

    return new Promise((resolve, reject) => {
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
              case HttpEventType.ResponseHeader:
              break;
              case HttpEventType.Response: {
                const obj: (T) = event.body;
                resolve(obj);
              }
            }
        },
        (error) => {
          reject(error);
        });
    });
  }

}