import { NgModule } from "@angular/core";
import { IconComponent } from "./icon.component";

@NgModule({
    declarations: [
        IconComponent
    ],
    exports: [
        IconComponent
    ],
    imports: [],
    providers: []
  })
  export class IconModule { }