import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iesa-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() icon: string;
  @Input() size: number = 36;
  @Input() mobileSize: number;
  @HostBinding('style.max-height') maxHeight;

  public innerWidth: number;

  constructor() { }

  ngOnInit() { this.maxHeight = `${this.size}px`; this.mobileSize = this.size; this.innerWidth = window.innerWidth; }

}
