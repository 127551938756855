import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ENDPOINTS } from '../configuration/ENDPOINTS';

export interface PublicSetting {
  id?: number,
  name?: string,
  unit?: string,
  value?: string,
  description?: string
}

export interface Attachment {
  id?: number,
  name?: string,
  type?: string,
  url?: string,
  extension?: string,
  size?: number,
  created_at?: number,
  updated_at?: number
}

@Injectable()
export class ConfigurationService {
  constructor(private api: ApiService) {}

  public configuration$ = new BehaviorSubject<PublicSetting[]>(null);
  public attachments$ = new BehaviorSubject<Attachment[]>(null);

  public getConfiguration(): Observable<any> {
    return this.configuration$.asObservable().pipe(
        switchMap((configuration) => {
          return configuration
            ? of(configuration)
            : this.api.request(ENDPOINTS.getConfiguration, 'GET').then(({data}) => this.configuration$.next(data)).catch(()=>null);
        })
      );
  }

  public getAttachmenst(directoryId: string): Observable<any> {
    return this.attachments$.asObservable().pipe(
        switchMap((attachments) => {
          return attachments
            ? of(attachments)
            : this.api.request(ENDPOINTS.getDirectory(directoryId), 'GET').then(({files})=> this.attachments$.next(files)).catch(()=>null);
        })
      );
  }
  
  public getBlob(url: string): Promise<any> {
    return this.api.request(ENDPOINTS.blob(url), 'GET', { responseType: 'blob'});
  }

}