import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IconModule } from "src/app/iesa-ui/icon/icon.module";
import { FooterComponent } from "./footer.component";

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        RouterModule,
        IconModule
    ],
    exports: [
        FooterComponent
    ],
    providers: []
  })
  export class FooterModule { }