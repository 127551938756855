import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ENDPOINTS } from '../configuration/ENDPOINTS';

export interface News {
    id?: number;
    article_id?: number;
    author_first_name?: string;
    author_last_name?: string;
    attachments_count?: number;
    name?: string;
    content?: string;
    valid_from?: string;
    valid_to?: string;
    created_at?: string;
    custom_fields?: [];
    image_url?: string;
    mobile_image_url?: string;
    default_image_url?: string;
    default_mobile_image_url?: string;
    duration?: number[];
    show_in_iesa?: boolean;
    show_in_bast?: boolean;
}

export interface NewsImage {
  created_at?: number;
  extension?: string;
  id?: number;
  name?: string;
  size?: number;
  type?: string;
  updated_at?: number
  url?: string;
}

@Injectable()
export class NewsService {
  constructor(private api: ApiService) {}

  public images$ = new BehaviorSubject<NewsImage[]>(null);
  public news$ = new BehaviorSubject<{data: News[], pages: number, total: number} | null>(null);

  public getNewsList(page?: number, per_page?: number): Promise<{ data: News[], pages: number, total: number }> {
    function buildParams() {
      if (!page || !per_page) { return '' }
      let query = '?';
      if (page) {
        query += `page=${page}&`;
      }
      if (per_page) {
        query += `per_page=${per_page}&`;
      }
      return query;
    }
  
    return this.api.request(ENDPOINTS.newsList + buildParams(), 'GET');
  }

  public getNews(id: number): Promise<any> {
    return this.api.request(ENDPOINTS.news(id), 'GET');
  }

  public getBlob(url: string): Promise<any> {
    return this.api.request(ENDPOINTS.blob(url), 'GET', { responseType: 'blob'});
  }

  public getNewsAttachments(id): Promise<any> {
    return this.api.request(ENDPOINTS.newsAttachments(id), 'GET').then(({data}) => data);
  }
  
  public getDefaultNewsImages(directoryId: string): Observable<any> {
    return this.images$.asObservable().pipe(
      switchMap((images) => {
        return images
          ? of(images)
          : this.api.request(ENDPOINTS.getDirectory(directoryId), 'GET').then(({files})=> this.images$.next(files));
      })
    );
  }

}