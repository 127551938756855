import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class MetaService {
  constructor(private meta: Meta ) {}

  public addTag(tag: string, content: string): void {
    this.meta.addTag({name: tag, content: content});
  }

  public removeTagElement(tag): void {
    this.meta.removeTagElement(tag);
  }

  removeMetaDescription(content: string) {
    const metaTags = document.querySelectorAll('meta[name="description"]');
    metaTags.forEach(tag => {
      const metaTag = tag as HTMLMetaElement;
      if (metaTag.content === content) {
        this.meta.removeTagElement(metaTag);
      }
    });
  }

}