import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ENDPOINTS } from '../configuration/ENDPOINTS';

export interface Supplier {
  alias?: string,
  alias_iesa?: string,
  city?: string,
  country_name?: string,
  created_at?: string,
  flat_number?: string,
  house_number?: string,
  id?: number,
  image_url?: string,
  email?: string,
  name?: string,
  post_code?: string,
  show_in_iesa?: boolean,
  street?: string,
  tax_number?: string,
  updated_at?: string,
  url?: string,
  phone?: string,
  opening_hours?: Array<{day: string, hours: string}>,
  latitude?: number,
  longitude?: number,
  is_iesa_default_location?: boolean,
  locations?: Location[]
}

export interface Location {
  id?: number;
  supplier_id?: number;
  country?: number;
  country_name?: string;
  name?: string;
  alias?: string;
  alias_iesa?: string;
  image_url?: string;
  url?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: string;
  flat_number?: string;
  phone?: string;
  email?: string;
  warehouses_count?: number;
  warehouses: any;
  contacts: any;
  opening_hours?: Array<{day: string, hours: string}>,
  latitude?: number,
  longitude?: number
}

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  constructor(private api: ApiService) {}

  public getSuppliers(phrase?: string): Promise<any> {
    function buildQuery() {
      if (!phrase) { return ''; }
      else {
        return `?q=${phrase}`;
      }
    }

    return this.api.request(ENDPOINTS.suppliers + buildQuery(), 'GET').then(({data}) => data);
  }

  public getLocation(supplierId: number): Promise<Location[]> {
    return this.api.request(ENDPOINTS.location(supplierId), 'GET').then(({data}) => data);
  }

}